<template>
  <div>
    <el-page-header @back="goBack" content="Details page"> </el-page-header>
    <h2>{{ questionTitle }}</h2>
    <div v-html="questionDetail"></div>
  </div>
</template>

<script>
import { getQuestionInfoApi } from '@/api/faqManage.js';
export default {
  name: 'Detail',
  data() {
    return {
      questionId: 0,
      questionTitle: '',
      questionDetail: [],
    };
  },
  created() {
    this.handleGetDetail();
  },
  methods: {
    handleGetDetail() {
      this.questionId = this.$route.query.id;
      this.questionTitle = this.$route.query.name;
      getQuestionInfoApi(this.questionId).then((res) => {
        console.log(res.data);
        this.questionDetail = res.data.faqs_detail;
      });
    },
    goBack() {
      this.$router.push('/');
    },
  },
};
</script>

<template>
  <el-container>
    <el-header>
      <NavBar />
    </el-header>
    <el-main>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </el-main>
  </el-container>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
export default {
  name: 'Home',
  components: {
    NavBar,
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.el-container {
  min-width: 800px;
  width: 70%;
  margin: 0 auto;
}
@media screen and (max-width: 1500px) {
  .el-container {
    width: 85%;
  }
}
@media screen and (max-width: 1300px) {
  .el-container {
    width: 90%;
  }
}
@media screen and (max-width: 1100px) {
  .el-container {
    width: 100%;
  }
}
</style>

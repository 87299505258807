import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Detail from '@/components/Detail.vue';
import Search from '@/components/Search';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/search',
    children: [
      {
        path: '/search',
        name: 'Search',
        component: Search,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: '/detail',
        name: 'Detail',
        component: Detail,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;

<template>
  <div>
    <el-table :data="questionsList" ref="table" :highlight-current-row="true">
      <el-table-column align="center" label="#" type="index"></el-table-column>
      <el-table-column label="Title" prop="faqs_name"></el-table-column>
      <el-table-column align="center" label="View" width="180px">
        <template slot-scope="scope">
          <el-button type="text" @click="toogleExpand(scope.row)">
            {{ scope.row.expansion ? 'Collapse' : 'Preview' }}
          </el-button>
          <el-button type="text" @click="handleViewDetail(scope.row)">View Detail</el-button>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template>
          <el-form label-position="left">
            <div v-html="questionDetail"></div>
          </el-form>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 10px"
      :current-page="queryInfo.pagenum"
      :page-size="queryInfo.pagesize"
      :page-sizes="[10, 20, 30, 50]"
      :total="total"
      @current-change="handleCurrentPageChange"
      @size-change="handlePageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>

<script>
import { getQuestionInfoApi } from '@/api/faqManage.js';
export default {
  name: 'List',
  props: {
    queryInfo: Object,
    total: Number,
    questionsList: Array,
    handleGetQuestionsList: Function,
  },
  data() {
    return {
      questionDetail: [],
    };
  },
  methods: {
    async toogleExpand(row) {
      const { data: res } = await getQuestionInfoApi(row.faqs_id);
      this.questionDetail = res.faqs_detail;
      let $table = this.$refs.table;
      this.questionsList.map((item) => {
        if (row.faqs_id !== item.faqs_id) {
          $table.toggleRowExpansion(item, false);
          item.expansion = false;
        } else {
          item.expansion = !item.expansion;
        }
      });
      $table.toggleRowExpansion(row);
    },

    handleViewDetail(row) {
      this.$router.push({
        name: 'Detail',
        query: {
          id: row.faqs_id,
          name: row.faqs_name,
        },
      });
    },

    handleCurrentPageChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.handleGetQuestionsList();
    },

    handlePageSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.handleGetQuestionsList();
    },
  },
};
</script>


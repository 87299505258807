<template>
  <div>
    <div id="search">
      <el-row :gutter="10" type="flex" justify="space-between">
        <el-col :span="5">
          <el-cascader
            placeholder="Try searching for: VW"
            :options="cateList"
            :props="cascaderProps"
            ref="cateCasRef"
            filterable
            clearable
            @change="handleCascaderChange"
          ></el-cascader>
        </el-col>
        <el-col>
          <div>
            <el-input
              clearable
              placeholder="Enter keywords to search"
              v-model="queryInfo.query"
              @clear="handleInputChange"
              @blur="handleInputChange"
              @keyup.enter.native="handleGetQuestionsList"
            >
              <el-button slot="append" icon="el-icon-search" @click="handleGetQuestionsList"></el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
    </div>

    <transition name="el-zoom-in-top">
      <List
        v-if="questionsList.length !== 0"
        :queryInfo.sync="queryInfo"
        :total="total"
        :questionsList="questionsList"
        :handleGetQuestionsList="handleGetQuestionsList"
      />
    </transition>
  </div>
</template>

<script>
// import Crypto from '@/utils/crypto.js';
import List from '@/components/List.vue';
import { getCateListApi, getQuestionsListApi, loginApi } from '@/api/faqManage.js';
export default {
  name: 'Search',
  components: {
    List,
  },
  data() {
    return {
      loginData: {
        username: 'Guest',
        // password: '123456',
        // password: 'de8f332d326b18fbe48a556943420c5a',
        password: '4acf8250f990661dbd4ae8569efdede1',
      },
      cateList: [],
      cascaderProps: {
        expandTrigger: 'hover',
        label: 'cat_name',
        value: 'cat_id',
        children: 'children',
        checkStrictly: true,
      },

      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10,
        is_del: '0',
        faqs_state: '1',
        cat_id: '',
      },
      total: 0,
      questionsList: [],
    };
  },
  created() {
    this.doLogin();
  },
  methods: {
    async doLogin() {
      // this.loginData.password = Crypto.encrypt(this.loginData.password);
      const { data: resLogin } = await loginApi(this.loginData);
      window.sessionStorage.setItem('token', resLogin.token);
      const { data: resCate } = await getCateListApi();
      this.cateList = resCate;
    },

    handleGetQuestionsList() {
      this.queryInfo.query = this.queryInfo.query.trim();
      getQuestionsListApi(this.queryInfo).then((res) => {
        this.questionsList = res.data.questions;
        this.total = res.data.total;
        if (this.total === 0) this.$message.error('No results found!');
        let tableData = this.questionsList;
        tableData.map((item) => {
          item.expansion = false;
        });
      });
    },

    handleInputChange() {
      if (this.queryInfo.cat_id || this.queryInfo.query.trim()) {
        this.handleGetQuestionsList();
      } else {
        this.questionsList = [];
      }
    },

    handleCascaderChange(val) {
      this.questionsList = [];
      this.$refs.cateCasRef.dropDownVisible = false; // 点击后收起下拉框
      if (val.length) {
        this.queryInfo.cat_id = val[val.length - 1];
        this.handleGetQuestionsList();
      } else {
        this.queryInfo.cat_id = '';
        if (this.queryInfo.query.trim()) {
          this.handleGetQuestionsList();
        }
      }
    },
  },
};
</script>

<template>
  <el-row>
    <el-col>
      <el-menu :default-active="$route.path" router mode="horizontal" @select="handleSelect">
        <el-menu-item>
          <el-row type="flex" align="bottom">
            <el-col><img src="../assets/logo.png" height="52"/></el-col>
            <el-col style="font-size:30px;">FAQ</el-col>
          </el-row>
        </el-menu-item>
        <el-menu-item index="/search">Home</el-menu-item>
        <el-menu-item index="/detail" v-show="enableDetail">Detail</el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {};
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  computed: {
    enableDetail() {
      if (this.$route.path.indexOf('search') !== -1) {
        return false;
      }
      return true;
    },
  },
};
</script>


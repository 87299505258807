import request from '@/utils/request.js'

export function getCateListApi(queryInfo) {
  return request({
    url: `categories`,
    method: 'get',
    params: queryInfo,
  });
}

export function getQuestionsListApi(queryInfo) {
  return request({
    url: `faqs`,
    method: 'get',
    params: queryInfo,
  });
}

export function getQuestionInfoApi(questionId) {
  return request({
    url: `faqs/${questionId}`,
    method: 'get',
  });
}

export function loginApi(data) {
  return request({
    url: `/login`,
    method: 'post',
    data,
  })
}

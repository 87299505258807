import Vue from 'vue';
import {
  Button,
  Form,
  FormItem,
  Input,
  Message,
  Container,
  Header,
  Main,
  Menu,
  MenuItem,
  Row,
  Col,
  Table,
  TableColumn,
  Pagination,
  Option,
  Cascader,
  Backtop,
  Icon,
  PageHeader,
} from 'element-ui';

import enLocale from '../../node_modules/element-ui/lib/locale/lang/en';
Vue.use({ enLocale });

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Option);
Vue.use(Cascader);
Vue.use(Backtop);
Vue.use(Icon);
Vue.use(PageHeader);

Vue.prototype.$message = Message;

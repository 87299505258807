import axios from 'axios';
import { Message } from 'element-ui';

// 创建一个axios的对象
const $http = axios.create({
  // baseURL: 'http://127.0.0.1:8888/api/private/v1/',
  baseURL:'http://api.faq.dynavin.com/api/private/v1/',
  timeout: 8000,
});

// request拦截器
$http.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = window.sessionStorage.getItem('token');
    return config;
  },
  (error) => {
    Message.error('Request timed out, please try again!');
    return Promise.reject(error);
  }
);

//response拦截器
$http.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.meta.status !== 200) {
      if (res.meta.status !== 201) {
        Message({
          type: 'error',
          message: res.meta.msg,
        });
        return;
      }
    }
    return res;
  },
  (error) => {
    Message.error('Request failed, please try again later!');
    return Promise.reject(error);
  }
);

export default $http;
